import React, { useState, useEffect } from "react";
import axios from "axios";

const App = () => {
  const [items, setItems] = useState([]);
  const [filterType, setFilterType] = useState("buying");
  const [selectedPrice, setSelectedPrice] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(process.env.REACT_APP_ENDPOINT, {
        headers: { "X-API-KEY": process.env.REACT_APP_API_KEY },
      });
      setItems(result.data.contents);
    };
    fetchData();
  }, []);

  const handleFilterChange = (e) => {
    setFilterType(e.target.value);
    setSelectedPrice(null); // フィルタータイプが変更されたら選択をリセット
  };

  const handlePriceSelection = (e) => {
    setSelectedPrice(e.target.value);
  };

  const prices = items.flatMap((item) => item.price.map((price) => (filterType === "buying" ? price.buying_price : price.selling_price)));
  const uniquePrices = Array.from(new Set(prices)).sort((a, b) => a - b); // 重複排除と並び替え

  const filteredItems = items.filter((item) => item.price.some((priceSetting) => (selectedPrice ? (filterType === "buying" ? priceSetting.buying_price === parseInt(selectedPrice) : priceSetting.selling_price === parseInt(selectedPrice)) : true)));

  const renderItemFunction = (itemFunction) => {
    // 文字列を `\n` で分割し、各部分を <span> 要素にラップして配列に格納
    // 配列の各要素の間に <br /> を挿入
    return itemFunction.split("\n").map((part, index, array) => (
      <React.Fragment key={index}>
        <span>{part}</span>
        {index < array.length - 1 && <br />} {/* 最後の要素以外の後ろに <br /> を挿入 */}
      </React.Fragment>
    ));
  };

  return (
    <div>
      <h1 className="text-2xl text-center font-bold py-5">
        <span className="text-sm block">風来のシレン6</span>
        杖識別サポーター
      </h1>
      <div className="flex gap-3 py-3 justify-center z-10 sticky top-0 px-4 items-center bg-neutral/80 backdrop-blur-sm">
        <select className="select" onChange={handleFilterChange}>
          <option value="buying">買値</option>
          <option value="selling">売値</option>
        </select>
        <select className="select" onChange={handlePriceSelection}>
          <option value="">選択してください</option>
          {uniquePrices.map((price, index) => (
            <option key={index} value={price}>
              {price}
            </option>
          ))}
        </select>
      </div>

      {/* <div className="overflow-x-auto">
        <table className="table table-zebra">
          <thead>
            <tr>
              <th>名前</th>
              <th>使用回数</th>
              <th>効果</th>
            </tr>
          </thead>
          <tbody>
            {filteredItems.map((item) => (
              <tr key={item.id}>
                <th>{item.name}</th>
                <td>{item.price.find((priceSetting) => (selectedPrice ? (filterType === "buying" ? priceSetting.buying_price === parseInt(selectedPrice) : priceSetting.selling_price === parseInt(selectedPrice)) : true))?.usable_times}</td>
                <td>{renderItemFunction(item.function)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div> */}
      {selectedPrice != null && (
        <div className="container mx-auto max-w-2xl">
          <ul className="w-full flex gap-3 flex-col py-3 px-3">
            {filteredItems.map((item) => (
              <li className="card bg-base-100 shadow-md" key={item.id}>
                <div className="card-body py-0 px-0">
                  <div className="collapse collapse-arrow">
                    <input type="checkbox" />
                    <h2 className="card-title collapse-title">
                      {item.name}
                      <div className="badge badge-accent">{item.price.find((priceSetting) => (selectedPrice ? (filterType === "buying" ? priceSetting.buying_price === parseInt(selectedPrice) : priceSetting.selling_price === parseInt(selectedPrice)) : true))?.usable_times}</div>
                    </h2>
                    <div className="collapse-content">
                      <p>{renderItemFunction(item.function)}</p>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default App;
